<template>
  <div id="current-ngb-list">
    <overlay-loading-progress
      :LoadingFlag="OverlayLoadingFlag"
      :LoadingText="OverlayLoadingText"
    ></overlay-loading-progress>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>
      <v-container class="py-0" v-if="floatButtonFlag">
        <v-speed-dial
          v-if="floatButtonFlag"
          v-model="fab"
          :top="top"
          :bottom="bottom"
          :right="right"
          :left="left"
          :direction="direction"
          :open-on-hover="hover"
          :transition="transition"
          :fixed="true"
        >
          <template v-slot:activator>
            <v-btn v-model="fab" color="blue darken-2" dark fab>
              <v-icon v-if="fab"> mdi-close </v-icon>
              <v-icon v-else> mdi-account-circle </v-icon>
            </v-btn>
          </template>
          <!-- <v-btn fab dark small color="green">
          <v-icon>mdi-pencil</v-icon>
        </v-btn> -->
          <v-btn
            fab
            dark
            small
            color="indigo"
            @click.prevent="addRecordPrompt = true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <!-- <v-btn fab dark small color="red">
          <v-icon>mdi-delete</v-icon>
        </v-btn> -->
        </v-speed-dial>
      </v-container>

      <v-dialog v-model="addRecordPrompt" persistent scrollable max-width="80%">
        <jcom-zone-board-members-assign
          :AddRecordPrompt="addRecordPrompt"
          :ZoneCode="ZoneCode"
          @hideAddRecordPrompt="hideAddRecordPrompt"
          v-if="addRecordPrompt"
        ></jcom-zone-board-members-assign>
      </v-dialog>

      <v-dialog
        v-model="editRecordPrompt"
        persistent
        scrollable
        max-width="80%"
      >
        <jcom-zone-board-members-edit
          :editRecordPrompt="editRecordPrompt"
          :ZoneCode="ZoneCode"
          :recordData="selectedData"
          @hideEditRecordPrompt="hideEditRecordPrompt"
          v-if="editRecordPrompt"
        ></jcom-zone-board-members-edit>
      </v-dialog>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container class="py-0" v-if="ResultFlag && rows.length == 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h4>No records found.</h4>
            </v-col>
          </v-row>
        </v-container>
        <v-row wrap>
          <v-col cols="12" md="12" align="center">
            <v-btn
              elevation="30"
              shaped
              tile
              small
              color="#9370d8"
              class="font-size-h6 white--text my-3 mr-5"
              @click.prevent="refreshPageData"
            >
              Refresh
            </v-btn>
            <v-btn
              v-if="ZoneCode > 0 && CreateFlag"
              elevation="30"
              shaped
              tile
              small
              color="#66cd00"
              class="font-size-h6 my-3 mr-3 white--text"
              @click.prevent="addRecordPrompt = true"
            >
              Assign Member
            </v-btn>
            <v-btn
              v-if="DownloadFlag"
              color="#8a2be2"
              @click.prevent="
                generateExcel(rows, ExcelFields, ExcelFileName, 'My Worksheet')
              "
              class="btn btn-primary font-size-h6 py-4 my-3 mr-3 white--text"
              >Download
            </v-btn>
          </v-col>
        </v-row>

        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row wrap>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h6><span class="text-danger">*</span> Zone</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid"
                  color="success"
                  class="btn btn-primary font-size-h6 py-4 my-3 mr-3 white--text"
                  @click="searchForm"
                >
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container class="py-0" v-if="rows.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3 class="text-primary">{{ rows.length }} members found.</h3>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <!-- <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search records here"
                single-line
                hide-details
              ></v-text-field>
              <br /> -->

              <v-data-iterator
                :items="rows"
                :items-per-page="-1"
                hide-default-footer
              >
                <template v-slot:header> </template>

                <template v-slot:default="props">
                  <v-row>
                    <v-col
                      v-for="item in props.items"
                      :key="item.MemberId"
                      cols="4"
                    >
                      <v-card raised hover elevation="25">
                        <v-card-title>
                          {{ item.MonthTxt }}
                        </v-card-title>
                        <v-card-text>
                          <v-avatar size="200">
                            <img
                              :src="item.ProfilePic"
                              :alt="item.MemberFullname"
                            />
                          </v-avatar>
                          <h3>{{ item.MemberFullname }}</h3>
                          <h3 class="text-primary">
                            {{ item.DesignationName }}
                          </h3>
                          <h4 style="color: green">{{ item.MembershipId }}</h4>
                          <h4 style="color: brown">{{ item.ZoneName }}</h4>
                          <h5 style="color: red">{{ item.LomName }}</h5>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            elevation="30"
                            shaped
                            tile
                            small
                            color="#008000"
                            class="font-size-h6 white--text"
                            @click="editData(item, e)"
                            v-if="item.DesignationId != 167 && EditFlag"
                          >
                            <v-icon>mdi-pencil</v-icon> Edit
                          </v-btn>
                          <v-btn
                            elevation="30"
                            shaped
                            tile
                            small
                            color="#ff0000"
                            class="font-size-h6 white--text"
                            @click="deleteAlert(item, e)"
                            v-if="DeleteFlag"
                          >
                            <v-icon>mdi-delete</v-icon> Delete
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
              </v-data-iterator>
            </v-col>
          </v-row>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import JcomZoneBoardMembersAssign from "@/view/pages/erp/jcom/jcom-zone-board/JcomZoneBoardMembersAssign.vue";
import JcomZoneBoardMembersEdit from "@/view/pages/erp/jcom/jcom-zone-board/JcomZoneBoardMembersEdit.vue";

export default {
  mixins: [common],
  components: {
    JcomZoneBoardMembersAssign,
    JcomZoneBoardMembersEdit,
  },
  data() {
    return {
      valid: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ResultFlag: false,

      CreateFlag: false,
      EditFlag: false,
      DeleteFlag: false,

      DownloadFlag: false,
      ExcelFileName: "",
      ExcelFields: {},

      ZipDownloadFlag: false,
      ZipFileName: "",

      OverlayLoadingFlag: false,
      OverlayLoadingText: "",

      refreshButtonFlag: false,
      floatButtonFlag: false,

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],

      DesignationIdRules: [(v) => !!v || "Designation is required"],
      DesignationId: "",
      DesignationIdOptions: [],
      DesignationIdOptionsLoading: false,

      rows: [],
      tableSelectFlag1: false,
      tableColumns1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      selectedData: {},

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,

      zone: {},
      lom: {},
      Member: {},

      MembershipId: "",
      MembershipIdRules: [(v) => !!v || "Membership Id is required"],

      YearIdRules: [(v) => !!v || "Year is required"],
      YearId: "",
      YearIdOptions: [],
      YearIdOptionsLoading: false,

      ApprovalDescription: "",
      ApprovalDescriptionRules: [],

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        this.pageData();
      }
    },
    DesignationIdOptions: function () {
      this.DesignationIdOptionsLoading = false;
    },
    ZoneCode: function () {
      this.resetForm();
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "jcom_zone_board",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.getZoneCodeOptions();
    },
    resetForm() {
      console.log("resetForm called");

      this.CreateFlag = false;
      this.EditFlag = false;
      this.DeleteFlag = false;

      this.DownloadFlag = false;
      this.ExcelFileName = "";
      this.ZipDownloadFlag = false;
      this.ZipFileName = "";

      this.rows = {};
      this.tableColumns1 = {};
      this.tableSelectFlag1 = {};
    },
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    hideAddRecordPrompt(flag) {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      console.log(flag);
      if (flag) {
        this.searchForm();
      }
    },
    hideEditRecordPrompt(flag) {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
      console.log(flag);
      if (flag) {
        this.searchForm();
      }
    },
    editData(tr) {
      console.log("editData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.selectedData = tr;
        this.editRecordPrompt = true;
      } else {
        var message = "Kindly select one member to edit";
        this.toast("error", message);
      }
    },
    getDesignationIdOptions() {
      console.log("getDesignationIdOptions called");
      this.DesignationIdOptionsLoading = true;
      var selectbox1_source = "DesignationId";
      var selectbox1_destination = "DesignationIdOptions";
      var selectbox1_url = "api/designation/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        OrganizationTypeId: 1,
        ExceptDesignationId: [1, 4, 5, 6, 7],
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    searchForm() {
      console.log("searchForm called");
      var ZoneCode = this.ZoneCode;
      console.log("ZoneCode=" + ZoneCode);

      if (ZoneCode != "") {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/year-wise-designation/lists";
        var upload = {
          UserInterface: 9,
          OrganizationTypeId: 7,
          ZoneCode: ZoneCode,
          YearCode: this.JciYearCode,
          ExceptDesignationId: [1, 5, 7],
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.DownloadFlag = false;
        this.ExcelFileName = "";
        this.ZipDownloadFlag = false;
        this.ZipFileName = "";

        this.rows = [];
        this.ResultFlag = false;
        this.LoadingFlag = true;
        this.CreateFlag = false;
        this.EditFlag = false;
        this.DeleteFlag = false;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.ResultFlag = true;
            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            var options = records.TableOptions;
            thisIns.CreateFlag = records.CreateButtonFlag;

            if (flag == 1) {
              thisIns.rows = records.TableData;
              thisIns.tableColumns1 = records.TableHeader;
              thisIns.tableSelectFlag1 = records.TableSelectFlag;
              thisIns.EditFlag = records.TableEditFlag;
              thisIns.DeleteFlag = records.TableDeleteFlag;
              thisIns.DownloadFlag = options.DownloadFlag;
              thisIns.ExcelFileName = options.ExcelFileName;
              thisIns.ExcelFields = options.ExcelFields;
              thisIns.ZipDownloadFlag = options.ZipDownloadFlag;
              thisIns.ZipFileName = options.ZipFileName;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.ResultFlag = true;
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Please select the Zone ";
        console.log("error=" + message);
        if (ZoneCode == "") {
          this.sweetAlert("error", message, false);
        }
      }
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.CurrentYearId;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/jcom/table/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    deleteAlert(tr) {
      console.log("deleteAlert called");
      console.log("tr=" + JSON.stringify(tr));
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.selectedData = tr;
        this.confirmAlert();
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    confirmAlert() {
      console.log("confirmAlert called");
      var tr = this.selectedData;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var htmlTxt = "";
        htmlTxt += "<br/>Member Name <b> " + tr.MemberFullname + "</b>";
        htmlTxt += "<br/>Designation <b> " + tr.DesignationName + "</b>";
        htmlTxt += "<br/>Membership Id <b> " + tr.MembershipId + "</b>";
        htmlTxt += "<br/><br/>Do you want to delete the data?";

        Swal.fire({
          title: "Are you sure?",
          text: "Do you want to delete the data?",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Delete`,
          confirmButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#3085d6",
        }).then((result) => {
          if (result.isConfirmed) {
            this.deleteRecord(tr);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    deleteRecord(tr) {
      console.log("deleteRecord called");

      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      console.log({ tr });

      if (n1 > 0) {
        this.OverlayLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/year-wise-designation/member-delete";
        var upload = {
          UserInterface: 5,
          OrganizationType: 7,
          Year: this.JciYearCode,
          Zone: tr.ZoneId,
          Lom: tr.LomId,
          Member: tr.MemberId,
          Designation: tr.DesignationId,
          YearwiseOfficesHeld: tr.YearwiseOfficesHeldId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        // thisIns.pageLoadingOn()

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.OverlayLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, false);
              thisIns.searchForm();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.OverlayLoadingFlag = false;
          });
      } else {
        console.log("some errors occured");
        var message = "Kindly select one member to delete";
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#current-ngb-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
}
</style>